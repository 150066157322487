import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function App() {
  const [score, setScore] = React.useState(parseFloat(localStorage.getItem("score")) || 600);
  const [isEdit, setIsEdit] = React.useState(false);
  const [schoolList, setSchoolList] = React.useState(JSON.parse(localStorage.getItem("schoolList")) || []);

  React.useEffect(() => {
    // console.log(schoolList);
    localStorage.setItem("schoolList", JSON.stringify(schoolList));
    // console.log("School List updated!");
    // console.log(schoolList);
  }, [schoolList]);

  React.useEffect(() => {
    localStorage.setItem("score", score);
  }, [score]);

  const onChange = (e) => {
    setScore(e.target.value);
  };

  const onAddScore = async (e) => {
    if (e.key === "Enter") {
      setIsEdit(false);
    }
  };

  const onRemoveSma = async (id) => {
    const newSchoolList = schoolList.filter((x) => x.id !== id);
    setSchoolList(newSchoolList);
  };

  const onAddSma = async (e) => {
    if (e.key === "Enter") {
      // console.log(e.target.value);
      const parseUrlArr = e.target.value.split("/");
      const sekolahId = parseUrlArr[7];
      if (typeof sekolahId === "undefined") {
        e.target.value = "";
        return;
      }
      addSma(sekolahId);
      e.target.value = "";
    }
  };

  const addSma = async (sekolahId) => {
    const res = await fetch(`https://api.ppdb.disdik.jabarprov.go.id/portal/school/${sekolahId}?populate=options`);
    const jsonRes = await res.json();
    const schoolData = jsonRes.result;

    const pendaftarRes = await fetch(
      `https://api.ppdb.disdik.jabarprov.go.id/portal/registrant?page=1&limit=500&pagination=true&orderby=score&order=desc&search=&columns[0][key]=name&columns[0][searchable]=true&columns[1][key]=registration_number&columns[1][searchable]=true&filters[0][key]=first_choice_school&filters[0][value]=${sekolahId}&filters[1][key]=option_type&filters[1][value]=prestasi-rapor`
    );
    const pendaftarJsonRes = await pendaftarRes.json();
    const pendaftarData = pendaftarJsonRes.result.itemsList;
    var ranking = 0;

    for (var i = 0; i <= pendaftarData.length; i++) {
      // console.log(`Comparing ${pendaftarData[i].score} with ${score}`);
      // console.log(i);
      if (typeof pendaftarData[i + 1] === "undefined") {
        // console.log(i);
        // console.log(pendaftarData[i]);
        // console.log(pendaftarData[i + 1]);
        ranking = i + 1;
        continue;
      }

      if (pendaftarData[i].score > score && score > pendaftarData[i + 1].score) {
        ranking = i + 1;
        break;
      }
    }
    // console.log(pendaftarData);

    const schoolItem = {
      id: schoolData.id,
      name: schoolData.name,
      quota_rapor: schoolData.options[5].quota,
      quota_zonasi: schoolData.options[6].quota,
      data: schoolData,
      pendaftar: pendaftarData,
      jumlah_pendaftar: pendaftarData.length,
      ranking: ranking,
      lulus: ranking < schoolData.options[5].quota,
    };

    // console.log(schoolItem);

    // Remove existing school if it's duplicate
    const newSchoolList = schoolList.filter((x) => x.id !== schoolItem.id);
    // console.log(newSchoolList);

    const newArr = [...newSchoolList, schoolItem];
    // console.log(newArr);

    setSchoolList(newArr);

    // const parseUrlArr =
    // https://ppdb.disdik.jabarprov.go.id/data-pendaftar/kota-bandung/sma/negeri/608f85bd78c5383cc367c2e4
    // https://api.ppdb.disdik.jabarprov.go.id/portal/school/608f85bf78c5383cc367c2f8?populate=options
  };

  // console.log(schoolList);

  const PosisiAnak = () => (
    <tr key="posisi">
      <td colSpan={7} align="center" className="posisi">
        <span style={{ color: "green", fontSize: "20px" }}>
          <b>POSISI ANAK DI SINI</b>
        </span>
      </td>
      <td align="right" className="posisi">
        <b>{score}</b>
      </td>
    </tr>
  );

  return (
    <div style={{ paddingLeft: 20 }}>
      <h1 style={{ marginBottom: 0 }}>Cek PPDB Jabar</h1>
      <b>Khusus Jalur Prestasi Raport</b>
      <br />
      <br />
      Masukkan Score Raport Anak:{" "}
      {isEdit ? (
        <div>
          <input onChange={onChange} onKeyPress={onAddScore} style={{ width: 40 }} value={score} />
        </div>
      ) : (
        <div>
          <a
            onClick={() => {
              setIsEdit(true);
            }}
          >
            {score} ✎
          </a>
        </div>
      )}
      <br />
      <table>
        {schoolList.length > 0 ? (
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Jumlah Pendaftar</th>
              {/* <th>Quota Rapor</th> */}
              {/* <th>Quota Zonasi</th> */}
              <th>Ranking Anak</th>
              <th>Lulus</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
        ) : null}
        <tbody>
          {schoolList.map((val, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{val.name}</td>
              <td align="center">{val.jumlah_pendaftar}</td>
              {/* <td align="center">{val.quota_rapor}</td> */}
              {/* <td align="center">{val.quota_zonasi}</td> */}
              <td align="center">
                <b>{val.ranking} </b>dari <b>{val.quota_rapor}</b> quota
              </td>
              <td align="center">
                {val.lulus ? (
                  <span style={{ color: "green", fontWeight: "bold" }}>Ya</span>
                ) : (
                  <span style={{ color: "red", fontWeight: "bold" }}>Tidak</span>
                )}
              </td>
              <td align="center">
                <a
                  onClick={() => {
                    addSma(val.id);
                  }}
                  style={{}}
                >
                  <span style={{ color: "green" }}>↺</span>
                </a>
                &nbsp;|&nbsp;
                <a
                  onClick={() => {
                    onRemoveSma(val.id);
                  }}
                  style={{}}
                >
                  <span style={{ color: "red" }}>✖</span>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <ul>
        <li>SMAN 3 608f85ba78c5383cc367c2c7</li>
        <li>SMAN 5 608f85ba78c5383cc367c2c5</li>
        <li>SMAN 8 608f85b778c5383cc367c2a2</li>
        <li>SMAN 20 608f85bd78c5383cc367c2e4</li>
        <li>SMAN 21 608f85b878c5383cc367c2ad</li>
        <li>SMAN 22 608f85b678c5383cc367c29f</li>
        <li>SMAN 24 608f85b978c5383cc367c2b7</li>
        <li>SMAN 25 608f85b878c5383cc367c2af</li>
      </ul> */}
      <br />
      Tambah SMA : <input onKeyPress={onAddSma} style={{ width: 600 }} />
      <br />
      (copas link tiap sekolah dari{" "}
      <a href="https://ppdb.disdik.jabarprov.go.id/cadisdik/7/data-pendaftar" target="_blank">
        https://ppdb.disdik.jabarprov.go.id/cadisdik/7/data-pendaftar
      </a>{" "}
      )
      <br />
      <br />
      <Tabs>
        <TabList>
          {schoolList.map((school, key) => (
            <Tab>{school.name}</Tab>
          ))}
        </TabList>

        {schoolList.map((school, key) => (
          <TabPanel>
            {/* Pendaftar Jalur Raport {school.name},  */}
            Jumlah Pendaftar: {school.pendaftar.length}
            <br />
            <br />
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Reg</th>
                  <th>Name</th>
                  <th>Pil.1</th>
                  <th>Jarak Pil.1</th>
                  <th>Pil.2</th>
                  <th>Jarak Pil.2</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {school.pendaftar.map((student, key) => {
                  var list = [];

                  var posisi;

                  // console.log(student.first_option.name);
                  // console.log(student.second_option.name);

                  var first_option = typeof student.first_option !== undefined ? student.first_option.name : "";
                  var second_option = "";

                  if (typeof student.second_option !== "undefined" && student.second_option !== null) {
                    // console.log(student.second_option);
                    second_option = student.second_option.name;
                  }

                  // console.log(student);
                  // console.log(student.score, score, key + 1); //, school.pendaftar[key + 1]);

                  // var barisPosisi = false;
                  // var endPosisi = false;

                  // if (typeof school.pendaftar[key + 1] === "undefined") {
                  //   barisPosisi = true;
                  //   endPosisi = true;
                  // }

                  // if (!barisPosisi) {
                  //   if (student.score > score && score > school.pendaftar[key + 1].score) {
                  //     barisPosisi = true;
                  //   }
                  // }

                  // if (barisPosisi) {
                  var nextPendaftarScore = 0;

                  if (typeof school.pendaftar[key + 1] !== "undefined") {
                    nextPendaftarScore = school.pendaftar[key + 1].score;
                  }

                  if (student.score > score && score > nextPendaftarScore) {
                    posisi = (
                      <tr key="posisi">
                        <td colSpan={7} align="center" className="posisi">
                          <span style={{ color: "green", fontSize: "20px" }}>
                            <b>POSISI ANAK DI SINI</b>
                          </span>
                        </td>
                        <td align="right" className="posisi">
                          <b>{score}</b>
                        </td>
                      </tr>
                    );
                  } else {
                    posisi = null;
                  }

                  var bgColor = key < school.quota_rapor ? "white" : "grey";
                  var bgColor = key < school.quota_rapor ? "white" : "#BBB";
                  var studentRow = (
                    <tr key={key} style={{ backgroundColor: bgColor }}>
                      <td>{key + 1}</td>
                      <td width={150}>{student.registration_number}</td>
                      <td>{student.name}</td>
                      <td align="center">{first_option}</td>
                      <td align="right">{student.distance1}</td>
                      <td align="center">{second_option}</td>
                      <td align="right">{student.distance2}</td>
                      <td align="right">{student.score}</td>
                    </tr>
                  );
                  list.push(studentRow);
                  list.push(posisi);

                  // if (endPosisi) {
                  //   list.push(studentRow);
                  //   list.push(posisi);
                  // } else {
                  //   list.push(posisi);
                  //   list.push(studentRow);
                  // }
                  return list;
                })}
              </tbody>
            </table>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
}

const style = {};
export default App;
